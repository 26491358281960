import React, { useState } from 'react';
import './App.css';

enum CardType {
  Atak = 'Atak',
  Obrona = 'Obrona',
  Zwiad = 'Zwiad',
  Zebranie = 'Zebranie',
  Zaklęcia = 'Zaklęcia',
  Ucieczka = 'Ucieczka',
  Joker = 'Joker',
}

function App() {
  const [cards, setCards] = useState<CardType[]>([]);

  const onDrawCards = () => {
    let deck = [
      CardType.Atak,
      CardType.Obrona,
      CardType.Zwiad,
      CardType.Zebranie,
      CardType.Zaklęcia,
      CardType.Ucieczka,
      CardType.Joker,
    ];
    const randCards: CardType[] = [];

    for (let i = 0; i < 4; i++) {
      const cardIdx = Math.floor(Math.random() * deck.length);

      randCards.push(deck[cardIdx]);
      deck = deck.filter((card) => card !== deck[cardIdx]);
    }

    setCards(randCards);
  };

  const renderCard = (card: CardType) => (
    <div key={card} className="deck-card">
      {card}
    </div>
  );

  return (
    <div>
      <div className="App-content">
        <header className="App-header">
          <button className="draw-button" onClick={onDrawCards}>
            Wylosuj karty
          </button>
        </header>
        {cards.length > 0 && (
          <div className="card-list">{cards.map(renderCard)}</div>
        )}
        <img src="./padoru.gif" className="padoru" alt="padoru" />
      </div>
    </div>
  );
}

export default App;
